export default class AddonsUnum {
    static sms = {
        name: 'sms',
        id: 17
    };
    static routes = {
        name: 'routes',
        id: 6
    };
    static drivers = {
        name: 'drivers',
        id: 7
    };
    static vehicles = {
        name: 'vehicles',
        id: 9
    };
    static imports = {
        name: 'imports',
        id: 0
    };
}