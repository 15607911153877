<script>
import {baseErrHandler, getUserRoutesText, updateUserRouteInfo} from "@/services/api";
import { VueEditor } from 'vue2-quill-editor'
export default {
  name: "TextRedactor",
  data() {
    return {
      content: '',
      customToolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
      ]
    }
  },
  components: {
    VueEditor
  },
  methods: {
    async saveReachText() {
      await baseErrHandler(async () => {
        await updateUserRouteInfo({reach_text: this.content});
      });
    },

    async getRoutesText() {
      await baseErrHandler(async () => {
        const user = await getUserRoutesText();

        this.content = user?.data?.user?.routes_info;
      });
    },
  },
  async mounted() {
    await this.getRoutesText();
  }
}
</script>

<template>

  <form action='' class='wysiwyg_form' @submit.prevent="">
    <vue-editor
        v-model="content"
        :editorToolbar="customToolbar"
        :theme='"snow"'
        :placeholder='"Enter message to display with each routes"'
    ></vue-editor>
    <button @click='saveReachText' class='green-white save-info'>Save route information</button>
  </form>

</template>

<style scoped lang="scss">

.save-info {
  margin-top: 20px;
}

.green-white {
  font-weight: 400;
}
</style>