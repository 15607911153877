<template>
  <div class="component">
    <div class="top_line text-left">
      <h2 class="title py-5 text-2xl fw-b">Addons</h2>
      <div class="sub_title py-5">
        Select an addon to add to your plan or manage your existing addons.
      </div>
    </div>
    <div class="addons bordered">
      <div class="head px-5 bg-grey-lighter py-5 flex text-left items-center justify-between gap-15">
        <div class="item w-1/6">
          Addon
        </div>
        <div class="item w-1/6">
          Status
        </div>
        <div class="item w-1/6">
          Units
        </div>
        <div class="item w-1/6">
          Price
        </div>
        <div class="item w-1/6">
          Total
        </div>
        <div class="item w-1/6">

        </div>
      </div>
      <div class="body px-5 text-left">
        <div v-for="(addon, index) in addons" :key="index" :class="{'border-b': index < addons.length - 2}">
          <div class="row py-5 flex" v-if="addon.price[0].price != 0">
            <div class="item w-1/6">
              {{ addon.description }}  {{addon.id}}
            </div>
            <div class=" item w-1/6">
              <span class="green_text" v-if="exists_addons.includes(addon.id)">
                Active
              </span>
              <span v-else-if="!applicableAddons.includes(addon.external_addon_id)">
                Unavailable
              </span>
              <span v-else>
                Not added
              </span>
            </div>

            <div class="item w-1/6">
              {{ addon.unit }}
            </div>
            <div class="item w-1/6" v-if="addon.external_addon_id == 'additional-route'">
              £{{ addon.price[0].price / 100 }}.00/{{addon.period_unit == 'monthly' ? 'm' : 'yr'}}
            </div>
            <div class="item w-1/6" v-else>
              £{{ addon.price[0].price / 100 }}.00/{{addon.period_unit == 'monthly' ? 'm' : 'yr'}}
            </div>
            <div class="item w-1/6" v-if="addon.external_addon_id == 'additional-route'">
              £{{ addon.price[0].price / 100 }}.00/{{addon.period_unit == 'monthly' ? 'm' : 'yr'}}
            </div>
            <div class="item w-1/6" v-else>
              £{{ addon.price[0].price / 100 }}.00/{{addon.period_unit == 'monthly' ? 'm' : 'yr'}}
            </div>
            <div class="item w-1/6">
              <span @click="addAddon(addon)"  v-if="exists_addons.includes(addon.id)" class="green_text pointer">Add units</span>
              <span v-else-if="!applicableAddons.includes(addon.external_addon_id)"></span>
              <span @click="addAddon(addon)" v-else class="green_text pointer">Select</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {infoMessage} from "../services/messages";
import {mapGetters} from "vuex";

export default {
  name: "Addons",
  data() {
    return {
      addons: [],
      subscriptionAddons: [],
      applicableAddons: [],
      exists_addons: []
    }
  },
  methods: {

    addAddon(addon){

      const { callbacks = {} } = {};
      const user = JSON.parse(localStorage.getItem('user'));

      this.getCbInstance.setCheckoutCallbacks(cart => {
        console.log(cart)
        return {
          loaded() {
            console.log('checkout opened');
            callbacks?.loaded?.();
          },
          close() {
            console.log('checkout closed');
            callbacks?.close?.();
          },
          success(hostedPageId) {
            console.log('success');
            callbacks?.success?.(hostedPageId);
          },
          step(value) {
            callbacks?.step?.(value);
          },
        };
        /* eslint-enable no-unused-expressions */
      });

      const http = this.$http;
      this.getCbInstance.openCheckout({
        hostedPage: async () =>
            await http.postAuth(`${http.apiUrl()}/subscriptions-addons/hosted-page`, {addon, user})
                .then(resp => resp?.data?.hosted_page),
        error(errors) {
          if (errors.response) {
            const msg = errors?.response?.data?.message?.error_msg || 'Oops. Something went wrong...';
            return infoMessage(msg.replaceAll('[id][0]', ''), 'error');
          }
        },
        close() {
          console.log('close')
        },
        success() {
          window.open( '/addon-purchase-success', '_self');
        }
      });
    },
    async getAddons() {
      try {
        const addons = await this.$http.getAuth(`${this.$http.apiUrl()}/addons`);
        const subscriptionAddons = await this.$http.getAuth(`${this.$http.apiUrl()}/subscriptions-addons`);
        const subscriptionPlanDetails = await this.$http.getAuth(`${this.$http.apiUrl()}/subscription-plans/find`);
        let ids = []
        Object.keys(subscriptionAddons.data).forEach(val => {
          ids.push(parseInt(subscriptionAddons.data[val].addon_plan_id));
        });
        this.exists_addons = ids;
        this.addons = addons.data.addons;
        this.applicableAddons = subscriptionPlanDetails.data.applicable_addons;
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.getAddons();
  },
  created () {
    window.addEventListener('message', receiveMessage, false);
    async function receiveMessage(event) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (event?.data?.key == 'cb.page_visited' && event?.data?.value == 'thankyou_screen') {
        let user = JSON.parse(localStorage.getItem('user'));
        user.user_subscription_id = user.id
        localStorage.setItem('user', JSON.stringify(user));
      }
      if(event?.data == 'cb.close' && user.user_subscription_id) {
        window.location.reload()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCbInstance'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.addons {
  border-radius: 8px;
}
</style>
