<template>
  <div class="component">
    <div class="top_line text-left">
      <h2 class="title pb-5 text-2xl fw-b">Usage</h2>
    </div>
    <div class="body">
      <div class="usages flex-col gap-15">
        <div class="group flex gap-15">
          <div class="name">Registered vehicles</div>
          <div class="value">{{ usedCount.vehicles }} / {{allowedCount.vehicles}}</div>
        </div>
        <div class="group flex gap-15">
          <div class="name">Generated routes</div>
          <div class="value">{{usedCount.routes}} / {{ allowedCount.routes }}</div>
        </div>
        <div class="group flex gap-15">
          <div class="name">Saved routes</div>
          <div class="value">{{ usedCount.saved_routes }}</div>
        </div>
        <div class="group flex gap-15">
          <div class="name">Drivers</div>
          <div class="value">{{usedCount.drivers}} / {{allowedCount.drivers}}</div>
        </div>
        <div class="group flex gap-15">
          <div class="name">Text messages</div>
          <div class="value">{{ usedCount.sms }} / {{allowedCount.sms}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import AddonsUnum from "@/enums/AddonsUnum";

export default {
  name: "Usage",
  data() {
    return {
      allowedCount: {
        routes: null,
        drivers: null,
        vehicles: null,
        sms: null
      },
      usedCount: {
        routes: null,
        drivers: null,
        vehicles: null,
        sms: null,
        saved_routes: null
      },
    }
  },
  methods: {
    ...mapMutations([
      'setLoading'
    ]),
    async usages() {
      this.setLoading(true)
      const res = await this.$http.getAuth(`${this.$http.apiUrl()}/users/usages`);
      this.allowedCount.routes = res?.data?.user_subscription?.subscription_plan?.restrictions.routes || 0;
      this.allowedCount.drivers = res?.data?.user_subscription?.subscription_plan?.restrictions.drivers || 0;
      this.allowedCount.vehicles = res?.data?.user_subscription?.subscription_plan?.restrictions.vehicles || 0;
      this.allowedCount.sms = res?.data?.user_subscription?.subscription_plan?.restrictions?.sms || 0;

      this.allowedCount.routes +=
          parseInt(this.addonCount(res?.data?.user_subscription.addons, AddonsUnum.routes.id))
      this.allowedCount.drivers += this.addonCount(res?.data?.user_subscription.addons, AddonsUnum.drivers.id)
      this.allowedCount.sms += this.addonCount(res?.data?.user_subscription.addons, AddonsUnum.sms.id)
      this.allowedCount.vehicles += this.addonCount(res?.data?.user_subscription.addons, AddonsUnum.vehicles.id)

      this.usedCount.routes = res?.data?.routes_log || null;
      this.usedCount.drivers = res?.data?.drivers.length || null;
      this.usedCount.vehicles = res?.data?.vehicles.length || null;
      this.usedCount.sms = res?.data?.sms_log || null;
      this.usedCount.saved_routes = res?.data?.saved_routes || null;
      this.setLoading(false)
    },
    addonCount(addons= [], id) {
      let result = null
      addons.forEach(addon => {
        if (addon.addon_plan_id === id && addon.quantity) {
          result = addon.quantity
        }
      })
      return Number(result)
    },
  },
  async mounted() {
    await this.usages()
  }
}
</script>

<style scoped>

</style>